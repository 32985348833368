import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Navbar from '../components/Navbar/Navbar';
import { Container, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getImage } from 'gatsby-plugin-image';
import { ContentCard } from '../components/ContentCard/ContentCard';
import JsxSeo from '../components/SEO/JsxSEO';
import { ThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from '../theme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  hero: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(12),
    },
  },
  title: {
    marginTop: 'auto',
    fontSize: `clamp(${theme.typography.h2.fontSize}, 10vw, 10rem)`,
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  subtitle: {
    marginBottom: 'auto',
  },
  section: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
  },
  item: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export default function Work({ data, location }) {
  const works = data.allMarkdownRemark.edges;
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={darkTheme}>
      <Layout>
        <JsxSeo
          title="Our work"
          description="From strategy to implementation based on a customer-first holistic product development approach."
          location={location}
        />

        <Navbar />

        <Container className={classes.hero} maxWidth={false}>
          <Typography variant="h1" className={classes.title}>
            A taste of what we do best
          </Typography>
          <Typography component="h2" variant="subtitle2" className={classes.subtitle}>
            A product team that delivers at every step.
          </Typography>
        </Container>

        <Container maxWidth="xl">
          <Grid container spacing={10} justifyContent={'space-around'}>
            {works
              .filter(work => !work.node.frontmatter.hidden)
              .map((work, index) => (
                <Grid item key={index} md={5} style={{ paddingTop: smDown ? 0 : index % 2 > 0 ? 80 : 0 }}>
                  <ContentCard
                    to={`/work${work.node.fields.slug}`}
                    cover={getImage(work.node.frontmatter.featuredImage)}
                    title={work.node.frontmatter.title}
                    subtitle={work.node.frontmatter.summary}
                    imageTitle={work.node.frontmatter.featuredImageTitle}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      </Layout>
    </ThemeProvider>
  );
}

/* eslint no-undef: "off" */
export const query = graphql`
  query WorkQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "work" } }, fileAbsolutePath: { regex: "/(/work)/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            summary
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, height: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            featuredImageTitle
            hidden
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
